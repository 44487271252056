<template>
  <footer class="footer-nav-bar navbar navbar-expand-sm border-top p-0 mt-3">
    <div
      class="container d-flex justify-content-center align-items-center h-100 p-0"
    >
      <p class="p-0 m-0">©2024-2025 Daily Food Serve. All Rights Reserved.</p>
      <p class="p-0 m-0 ps-1">
        <a
          class="email-style theme-text-color"
          href="mailto:care@dailyfoodserve.com"
          >Contact Us: care@dailyfoodserve.com</a
        >
      </p>
    </div>
  </footer>
</template>

<script></script>

<style>
.footer-nav-bar {
  /* position: absolute !important;
  bottom: 0;
  left: 0; */
  height: 60px;
  max-height: 60px;
  background: #fff;
  width: 100%;
}
</style>
