<template>
  <div class="col p-0 selct-sountry-wrapper">
    <p class="p-0 m-0">Select country</p>
    <div class="country-select inside">
      <multiselect
        class="country-list"
        v-model="countryValue"
        placeholder="Search here..."
        label="name"
        track-by="name"
        :options="countryData"
        :option-height="200"
        :show-labels="false"
        @select="countrySelect"
      >
        <template #singleLabel="props">
          <!-- Selected item show here -->
          <div class="option__desc" :title="props.option.name">
            <div
              class="country d-flex align-items-center"
              :data-country-code="props.option.iso2"
            >
              <div class="flag me-1" :class="[props.option.iso2]"></div>
              <span class="country-name">{{ props.option.name }} </span>
            </div>
          </div>
        </template>
        <template #option="props">
          <div class="option__desc">
            <div
              class="country d-flex align-items-center"
              :data-country-code="props.option.iso2"
            >
              <div class="flag me-1" :class="[props.option.iso2]"></div>
              <span class="country-name">{{ props.option.name }} </span>
            </div>
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import CountryData from "./countryData.js";

export default {
  components: {
    Multiselect,
  },
  emits: ['selectedCountrySent'],
  data() {
    return {
      countryValue: {
        name: "India (भारत)",
        iso2: "in",
      },
      countryData: CountryData,
    };
  },
  mounted() {
    this.$emit("selectedCountrySent", this.countryValue);
  },
  methods: {
    countrySelect(val) {
      // console.log("dfasfdsfdsf", val);
      this.$emit("selectedCountrySent", val);
    },
  },
};
</script>

<style>
@import "./Coutry.css";
</style>
