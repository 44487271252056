import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createMetaManager } from "vue-meta";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueGtag from "vue-gtag";
const app = createApp(App);
// Create meta manager
const metaManager = createMetaManager();
// Register components
app.component("EasyDataTable", Vue3EasyDataTable);
// Use plugins
app.use(VueGtag, {
  config: { id: "G-F6HRMT2WZ1" },
});
app.use(router);
app.use(metaManager); // Use meta manager here
app.mount("#app");
