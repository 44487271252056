<template>
  <nav class="navbar navbar-expand-sm border-bottom p-0 log-container">
    <div
      class="container d-flex justify-content-center h-100 p-0 position-relative log-wrapper"
    >
      <a href="/" class="navbar-brand h-100 p-0 px-3 m-0">
        <img
          class="d-inline-block w-100 h-100 p-0"
          src="../../assets/DFS_Logo.svg"
          alt="Logo"
        />
      </a>
      <!-- <div class="select-country d-none d-sm-block position-absolute end-0">
        <CountryComponent />
      </div> -->
    </div>
    <!-- <div class="d-block d-sm-none"><CountryComponent /></div> -->
  </nav>
  <nav class="navbar position-absolute top-0 end-0 log-container">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            <router-link
              class="nav-link"
              :class="{ active: $route.path === '/' }"
              aria-current="page"
              to="/"
            >
              Daily Food Serve
            </router-link>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body px-0">
          <ul class="navbar-nav justify-content-end flex-grow-1">
            <li class="nav-item">
              <router-link
                class="nav-link px-3"
                :class="{ active: $route.path === '/' }"
                aria-current="page"
                to="/"
              >
                Home
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link px-3"
                :class="{ active: $route.path === '/about' }"
                to="/about"
              >
                About Us
              </router-link>
            </li>
            <!-- <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Dropdown
              </a>
              <ul class="dropdown-menu">
                <li>
                  <router-link class="dropdown-item" to="/action">
                    Action
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" to="/another-action">
                    Another action
                  </router-link>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <router-link class="dropdown-item" to="/something-else">
                    Something else here
                  </router-link>
                </li>
              </ul>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// import CountryComponent from "../Country/CountryComponent.vue";
export default {
  components: {
    // CountryComponent,
  },
};
</script>

<style>
.log-container {
  height: 90px;
  max-height: 90px;
}
.log-wrapper {
  width: 500px;
}
@media only screen and (max-width: 767px) {
  .log-container {
    height: 60px;
    max-height: 60px;
  }
  .log-wrapper {
    width: 300px !important;
    height: 60px;
  }
}
/* .select-country {
  top: 50%;
  transform: translateY(-50%);
} */
.nav-item .router-link-active {
  background-color: #f5f5f5;
}
</style>
