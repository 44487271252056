<template>
  <div class="container-fluid p-0 position-relative h-100">
    <HeaderComponent />
    <router-view></router-view>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "./components/Header/HeaderComponent.vue";
import FooterComponent from "./components/Footer/FooterComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.theme-text-color {
  color: #055160;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.food-card-wrapper::-webkit-scrollbar,
.full_year_food_menu_wrapper .nav-tabs::-webkit-scrollbar {
  display: none;
}
.font-size-16 {
  font-size: 16px;
}
</style>
