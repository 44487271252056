const addFoodData = [
  // {
  //   name: "Afghanistan (‫افغانستان‬‎)",
  //   iso2: "af",
  // },
  // {
  //   name: "Åland Islands (Åland)",
  //   iso2: "ax",
  // },
  // {
  //   name: "Albania (Shqipëri)",
  //   iso2: "al",
  // },
  // {
  //   name: "Algeria (‫الجزائر‬‎)",
  //   iso2: "dz",
  // },
  // {
  //   name: "American Samoa",
  //   iso2: "as",
  // },
  // {
  //   name: "Andorra",
  //   iso2: "ad",
  // },
  // {
  //   name: "Angola",
  //   iso2: "ao",
  // },
  // {
  //   name: "Anguilla",
  //   iso2: "ai",
  // },
  // {
  //   name: "Antarctica",
  //   iso2: "aq",
  // },
  // {
  //   name: "Antigua and Barbuda",
  //   iso2: "ag",
  // },
  // {
  //   name: "Argentina",
  //   iso2: "ar",
  // },
  // {
  //   name: "Armenia (Հայաստան)",
  //   iso2: "am",
  // },
  // {
  //   name: "Aruba",
  //   iso2: "aw",
  // },
  // {
  //   name: "Australia",
  //   iso2: "au",
  // },
  // {
  //   name: "Austria (Österreich)",
  //   iso2: "at",
  // },
  // {
  //   name: "Azerbaijan (Azərbaycan)",
  //   iso2: "az",
  // },
  // {
  //   name: "Bahamas",
  //   iso2: "bs",
  // },
  // {
  //   name: "Bahrain (‫البحرين‬‎)",
  //   iso2: "bh",
  // },
  // {
  //   name: "Bangladesh (বাংলাদেশ)",
  //   iso2: "bd",
  // },
  // {
  //   name: "Barbados",
  //   iso2: "bb",
  // },
  // {
  //   name: "Belarus (Беларусь)",
  //   iso2: "by",
  // },
  // {
  //   name: "Belgium (België)",
  //   iso2: "be",
  // },
  // {
  //   name: "Belize",
  //   iso2: "bz",
  // },
  // {
  //   name: "Benin (Bénin)",
  //   iso2: "bj",
  // },
  // {
  //   name: "Bermuda",
  //   iso2: "bm",
  // },
  // {
  //   name: "Bhutan (འབྲུག)",
  //   iso2: "bt",
  // },
  // {
  //   name: "Bolivia",
  //   iso2: "bo",
  // },
  // {
  //   name: "Bosnia and Herzegovina (Босна и Херцеговина)",
  //   iso2: "ba",
  // },
  // {
  //   name: "Botswana",
  //   iso2: "bw",
  // },
  // {
  //   name: "Bouvet Island (Bouvetøya)",
  //   iso2: "bv",
  // },
  // {
  //   name: "Brazil (Brasil)",
  //   iso2: "br",
  // },
  // {
  //   name: "British Indian Ocean Territory",
  //   iso2: "io",
  // },
  // {
  //   name: "British Virgin Islands",
  //   iso2: "vg",
  // },
  // {
  //   name: "Brunei",
  //   iso2: "bn",
  // },
  // {
  //   name: "Bulgaria (България)",
  //   iso2: "bg",
  // },
  // {
  //   name: "Burkina Faso",
  //   iso2: "bf",
  // },
  // {
  //   name: "Burundi (Uburundi)",
  //   iso2: "bi",
  // },
  // {
  //   name: "Cambodia (កម្ពុជា)",
  //   iso2: "kh",
  // },
  // {
  //   name: "Cameroon (Cameroun)",
  //   iso2: "cm",
  // },
  // {
  //   name: "Canada",
  //   iso2: "ca",
  // },
  // {
  //   name: "Cape Verde (Kabu Verdi)",
  //   iso2: "cv",
  // },
  // {
  //   name: "Caribbean Netherlands",
  //   iso2: "bq",
  // },
  // {
  //   name: "Cayman Islands",
  //   iso2: "ky",
  // },
  // {
  //   name: "Central African Republic (République Centrafricaine)",
  //   iso2: "cf",
  // },
  // {
  //   name: "Chad (Tchad)",
  //   iso2: "td",
  // },
  // {
  //   name: "Chile",
  //   iso2: "cl",
  // },
  // {
  //   name: "China (中国)",
  //   iso2: "cn",
  // },
  // {
  //   name: "Christmas Island",
  //   iso2: "cx",
  // },
  // {
  //   name: "Cocos (Keeling) Islands (Kepulauan Cocos (Keeling))",
  //   iso2: "cc",
  // },
  // {
  //   name: "Colombia",
  //   iso2: "co",
  // },
  // {
  //   name: "Comoros (‫جزر القمر‬‎)",
  //   iso2: "km",
  // },
  // {
  //   name: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
  //   iso2: "cd",
  // },
  // {
  //   name: "Congo (Republic) (Congo-Brazzaville)",
  //   iso2: "cg",
  // },
  // {
  //   name: "Cook Islands",
  //   iso2: "ck",
  // },
  // {
  //   name: "Costa Rica",
  //   iso2: "cr",
  // },
  // {
  //   name: "Côte d’Ivoire",
  //   iso2: "ci",
  // },
  // {
  //   name: "Croatia (Hrvatska)",
  //   iso2: "hr",
  // },
  // {
  //   name: "Cuba",
  //   iso2: "cu",
  // },
  // {
  //   name: "Curaçao",
  //   iso2: "cw",
  // },
  // {
  //   name: "Cyprus (Κύπρος)",
  //   iso2: "cy",
  // },
  // {
  //   name: "Czech Republic (Česká republika)",
  //   iso2: "cz",
  // },
  // {
  //   name: "Denmark (Danmark)",
  //   iso2: "dk",
  // },
  // {
  //   name: "Djibouti",
  //   iso2: "dj",
  // },
  // {
  //   name: "Dominica",
  //   iso2: "dm",
  // },
  // {
  //   name: "Dominican Republic (República Dominicana)",
  //   iso2: "do",
  // },
  // {
  //   name: "Ecuador",
  //   iso2: "ec",
  // },
  // {
  //   name: "Egypt (‫مصر‬‎)",
  //   iso2: "eg",
  // },
  // {
  //   name: "El Salvador",
  //   iso2: "sv",
  // },
  // {
  //   name: "Equatorial Guinea (Guinea Ecuatorial)",
  //   iso2: "gq",
  // },
  // {
  //   name: "Eritrea",
  //   iso2: "er",
  // },
  // {
  //   name: "Estonia (Eesti)",
  //   iso2: "ee",
  // },
  // {
  //   name: "Ethiopia",
  //   iso2: "et",
  // },
  // {
  //   name: "Falkland Islands (Islas Malvinas)",
  //   iso2: "fk",
  // },
  // {
  //   name: "Faroe Islands (Føroyar)",
  //   iso2: "fo",
  // },
  // {
  //   name: "Fiji",
  //   iso2: "fj",
  // },
  // {
  //   name: "Finland (Suomi)",
  //   iso2: "fi",
  // },
  // {
  //   name: "France",
  //   iso2: "fr",
  // },
  // {
  //   name: "French Guiana (Guyane française)",
  //   iso2: "gf",
  // },
  // {
  //   name: "French Polynesia (Polynésie française)",
  //   iso2: "pf",
  // },
  // {
  //   name: "French Southern Territories (Terres australes françaises)",
  //   iso2: "tf",
  // },
  // {
  //   name: "Gabon",
  //   iso2: "ga",
  // },
  // {
  //   name: "Gambia",
  //   iso2: "gm",
  // },
  // {
  //   name: "Georgia (საქართველო)",
  //   iso2: "ge",
  // },
  // {
  //   name: "Germany (Deutschland)",
  //   iso2: "de",
  // },
  // {
  //   name: "Ghana (Gaana)",
  //   iso2: "gh",
  // },
  // {
  //   name: "Gibraltar",
  //   iso2: "gi",
  // },
  // {
  //   name: "Greece (Ελλάδα)",
  //   iso2: "gr",
  // },
  // {
  //   name: "Greenland (Kalaallit Nunaat)",
  //   iso2: "gl",
  // },
  // {
  //   name: "Grenada",
  //   iso2: "gd",
  // },
  // {
  //   name: "Guadeloupe",
  //   iso2: "gp",
  // },
  // {
  //   name: "Guam",
  //   iso2: "gu",
  // },
  // {
  //   name: "Guatemala",
  //   iso2: "gt",
  // },
  // {
  //   name: "Guernsey",
  //   iso2: "gg",
  // },
  // {
  //   name: "Guinea (Guinée)",
  //   iso2: "gn",
  // },
  // {
  //   name: "Guinea-Bissau (Guiné Bissau)",
  //   iso2: "gw",
  // },
  // {
  //   name: "Guyana",
  //   iso2: "gy",
  // },
  // {
  //   name: "Haiti",
  //   iso2: "ht",
  // },
  // {
  //   name: "Heard Island and Mcdonald Islands",
  //   iso2: "hm",
  // },
  // {
  //   name: "Honduras",
  //   iso2: "hn",
  // },
  // {
  //   name: "Hong Kong (香港)",
  //   iso2: "hk",
  // },
  // {
  //   name: "Hungary (Magyarország)",
  //   iso2: "hu",
  // },
  // {
  //   name: "Iceland (Ísland)",
  //   iso2: "is",
  // },
  {
    name: "India (भारत)",
    iso2: "in",
  },
  // {
  //   name: "Indonesia",
  //   iso2: "id",
  // },
  // {
  //   name: "Iran (‫ایران‬‎)",
  //   iso2: "ir",
  // },
  // {
  //   name: "Iraq (‫العراق‬‎)",
  //   iso2: "iq",
  // },
  // {
  //   name: "Ireland",
  //   iso2: "ie",
  // },
  // {
  //   name: "Isle of Man",
  //   iso2: "im",
  // },
  // {
  //   name: "Israel (‫ישראל‬‎)",
  //   iso2: "il",
  // },
  // {
  //   name: "Italy (Italia)",
  //   iso2: "it",
  // },
  // {
  //   name: "Jamaica",
  //   iso2: "jm",
  // },
  // {
  //   name: "Japan (日本)",
  //   iso2: "jp",
  // },
  // {
  //   name: "Jersey",
  //   iso2: "je",
  // },
  // {
  //   name: "Jordan (‫الأردن‬‎)",
  //   iso2: "jo",
  // },
  // {
  //   name: "Kazakhstan (Казахстан)",
  //   iso2: "kz",
  // },
  // {
  //   name: "Kenya",
  //   iso2: "ke",
  // },
  // {
  //   name: "Kiribati",
  //   iso2: "ki",
  // },
  // {
  //   name: "Kosovo (Kosovë)",
  //   iso2: "xk",
  // },
  // {
  //   name: "Kuwait (‫الكويت‬‎)",
  //   iso2: "kw",
  // },
  // {
  //   name: "Kyrgyzstan (Кыргызстан)",
  //   iso2: "kg",
  // },
  // {
  //   name: "Laos (ລາວ)",
  //   iso2: "la",
  // },
  // {
  //   name: "Latvia (Latvija)",
  //   iso2: "lv",
  // },
  // {
  //   name: "Lebanon (‫لبنان‬‎)",
  //   iso2: "lb",
  // },
  // {
  //   name: "Lesotho",
  //   iso2: "ls",
  // },
  // {
  //   name: "Liberia",
  //   iso2: "lr",
  // },
  // {
  //   name: "Libya (‫ليبيا‬‎)",
  //   iso2: "ly",
  // },
  // {
  //   name: "Liechtenstein",
  //   iso2: "li",
  // },
  // {
  //   name: "Lithuania (Lietuva)",
  //   iso2: "lt",
  // },
  // {
  //   name: "Luxembourg",
  //   iso2: "lu",
  // },
  // {
  //   name: "Macau (澳門)",
  //   iso2: "mo",
  // },
  // {
  //   name: "Macedonia (FYROM) (Македонија)",
  //   iso2: "mk",
  // },
  // {
  //   name: "Madagascar (Madagasikara)",
  //   iso2: "mg",
  // },
  // {
  //   name: "Malawi",
  //   iso2: "mw",
  // },
  // {
  //   name: "Malaysia",
  //   iso2: "my",
  // },
  // {
  //   name: "Maldives",
  //   iso2: "mv",
  // },
  // {
  //   name: "Mali",
  //   iso2: "ml",
  // },
  // {
  //   name: "Malta",
  //   iso2: "mt",
  // },
  // {
  //   name: "Marshall Islands",
  //   iso2: "mh",
  // },
  // {
  //   name: "Martinique",
  //   iso2: "mq",
  // },
  // {
  //   name: "Mauritania (‫موريتانيا‬‎)",
  //   iso2: "mr",
  // },
  // {
  //   name: "Mauritius (Moris)",
  //   iso2: "mu",
  // },
  // {
  //   name: "Mayotte",
  //   iso2: "yt",
  // },
  // {
  //   name: "Mexico (México)",
  //   iso2: "mx",
  // },
  // {
  //   name: "Micronesia",
  //   iso2: "fm",
  // },
  // {
  //   name: "Moldova (Republica Moldova)",
  //   iso2: "md",
  // },
  // {
  //   name: "Monaco",
  //   iso2: "mc",
  // },
  // {
  //   name: "Mongolia (Монгол)",
  //   iso2: "mn",
  // },
  // {
  //   name: "Montenegro (Crna Gora)",
  //   iso2: "me",
  // },
  // {
  //   name: "Montserrat",
  //   iso2: "ms",
  // },
  // {
  //   name: "Morocco (‫المغرب‬‎)",
  //   iso2: "ma",
  // },
  // {
  //   name: "Mozambique (Moçambique)",
  //   iso2: "mz",
  // },
  // {
  //   name: "Myanmar (Burma) (မြန်မာ)",
  //   iso2: "mm",
  // },
  // {
  //   name: "Namibia (Namibië)",
  //   iso2: "na",
  // },
  // {
  //   name: "Nauru",
  //   iso2: "nr",
  // },
  // {
  //   name: "Nepal (नेपाल)",
  //   iso2: "np",
  // },
  // {
  //   name: "Netherlands (Nederland)",
  //   iso2: "nl",
  // },
  // {
  //   name: "New Caledonia (Nouvelle-Calédonie)",
  //   iso2: "nc",
  // },
  // {
  //   name: "New Zealand",
  //   iso2: "nz",
  // },
  // {
  //   name: "Nicaragua",
  //   iso2: "ni",
  // },
  // {
  //   name: "Niger (Nijar)",
  //   iso2: "ne",
  // },
  // {
  //   name: "Nigeria",
  //   iso2: "ng",
  // },
  // {
  //   name: "Niue",
  //   iso2: "nu",
  // },
  // {
  //   name: "Norfolk Island",
  //   iso2: "nf",
  // },
  // {
  //   name: "North Korea (조선 민주주의 인민 공화국)",
  //   iso2: "kp",
  // },
  // {
  //   name: "Northern Mariana Islands",
  //   iso2: "mp",
  // },
  // {
  //   name: "Norway (Norge)",
  //   iso2: "no",
  // },
  // {
  //   name: "Oman (‫عُمان‬‎)",
  //   iso2: "om",
  // },
  // {
  //   name: "Pakistan (‫پاکستان‬‎)",
  //   iso2: "pk",
  // },
  // {
  //   name: "Palau",
  //   iso2: "pw",
  // },
  // {
  //   name: "Palestine (‫فلسطين‬‎)",
  //   iso2: "ps",
  // },
  // {
  //   name: "Panama (Panamá)",
  //   iso2: "pa",
  // },
  // {
  //   name: "Papua New Guinea",
  //   iso2: "pg",
  // },
  // {
  //   name: "Paraguay",
  //   iso2: "py",
  // },
  // {
  //   name: "Peru (Perú)",
  //   iso2: "pe",
  // },
  // {
  //   name: "Philippines",
  //   iso2: "ph",
  // },
  // {
  //   name: "Pitcairn Islands",
  //   iso2: "pn",
  // },
  // {
  //   name: "Poland (Polska)",
  //   iso2: "pl",
  // },
  // {
  //   name: "Portugal",
  //   iso2: "pt",
  // },
  // {
  //   name: "Puerto Rico",
  //   iso2: "pr",
  // },
  // {
  //   name: "Qatar (‫قطر‬‎)",
  //   iso2: "qa",
  // },
  // {
  //   name: "Réunion (La Réunion)",
  //   iso2: "re",
  // },
  // {
  //   name: "Romania (România)",
  //   iso2: "ro",
  // },
  // {
  //   name: "Russia (Россия)",
  //   iso2: "ru",
  // },
  // {
  //   name: "Rwanda",
  //   iso2: "rw",
  // },
  // {
  //   name: "Saint Barthélemy (Saint-Barthélemy)",
  //   iso2: "bl",
  // },
  // {
  //   name: "Saint Helena",
  //   iso2: "sh",
  // },
  // {
  //   name: "Saint Kitts and Nevis",
  //   iso2: "kn",
  // },
  // {
  //   name: "Saint Lucia",
  //   iso2: "lc",
  // },
  // {
  //   name: "Saint Martin (Saint-Martin (partie française))",
  //   iso2: "mf",
  // },
  // {
  //   name: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
  //   iso2: "pm",
  // },
  // {
  //   name: "Saint Vincent and the Grenadines",
  //   iso2: "vc",
  // },
  // {
  //   name: "Samoa",
  //   iso2: "ws",
  // },
  // {
  //   name: "San Marino",
  //   iso2: "sm",
  // },
  // {
  //   name: "São Tomé and Príncipe (São Tomé e Príncipe)",
  //   iso2: "st",
  // },
  // {
  //   name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
  //   iso2: "sa",
  // },
  // {
  //   name: "Senegal (Sénégal)",
  //   iso2: "sn",
  // },
  // {
  //   name: "Serbia (Србија)",
  //   iso2: "rs",
  // },
  // {
  //   name: "Seychelles",
  //   iso2: "sc",
  // },
  // {
  //   name: "Sierra Leone",
  //   iso2: "sl",
  // },
  // {
  //   name: "Singapore",
  //   iso2: "sg",
  // },
  // {
  //   name: "Sint Maarten",
  //   iso2: "sx",
  // },
  // {
  //   name: "Slovakia (Slovensko)",
  //   iso2: "sk",
  // },
  // {
  //   name: "Slovenia (Slovenija)",
  //   iso2: "si",
  // },
  // {
  //   name: "Solomon Islands",
  //   iso2: "sb",
  // },
  // {
  //   name: "Somalia (Soomaaliya)",
  //   iso2: "so",
  // },
  // {
  //   name: "South Africa",
  //   iso2: "za",
  // },
  // {
  //   name: "South Georgia & South Sandwich Islands",
  //   iso2: "gs",
  // },
  // {
  //   name: "South Korea (대한민국)",
  //   iso2: "kr",
  // },
  // {
  //   name: "South Sudan (‫جنوب السودان‬‎)",
  //   iso2: "ss",
  // },
  // {
  //   name: "Spain (España)",
  //   iso2: "es",
  // },
  // {
  //   name: "Sri Lanka (ශ්‍රී ලංකාව)",
  //   iso2: "lk",
  // },
  // {
  //   name: "Sudan (‫السودان‬‎)",
  //   iso2: "sd",
  // },
  // {
  //   name: "Suriname",
  //   iso2: "sr",
  // },
  // {
  //   name: "Svalbard and Jan Mayen (Svalbard og Jan Mayen)",
  //   iso2: "sj",
  // },
  // {
  //   name: "Swaziland",
  //   iso2: "sz",
  // },
  // {
  //   name: "Sweden (Sverige)",
  //   iso2: "se",
  // },
  // {
  //   name: "Switzerland (Schweiz)",
  //   iso2: "ch",
  // },
  // {
  //   name: "Syria (‫سوريا‬‎)",
  //   iso2: "sy",
  // },
  // {
  //   name: "Taiwan (台灣)",
  //   iso2: "tw",
  // },
  // {
  //   name: "Tajikistan",
  //   iso2: "tj",
  // },
  // {
  //   name: "Tanzania",
  //   iso2: "tz",
  // },
  // {
  //   name: "Thailand (ไทย)",
  //   iso2: "th",
  // },
  // {
  //   name: "Timor-Leste",
  //   iso2: "tl",
  // },
  // {
  //   name: "Togo",
  //   iso2: "tg",
  // },
  // {
  //   name: "Tokelau",
  //   iso2: "tk",
  // },
  // {
  //   name: "Tonga",
  //   iso2: "to",
  // },
  // {
  //   name: "Trinidad and Tobago",
  //   iso2: "tt",
  // },
  // {
  //   name: "Tunisia (‫تونس‬‎)",
  //   iso2: "tn",
  // },
  // {
  //   name: "Turkey (Türkiye)",
  //   iso2: "tr",
  // },
  // {
  //   name: "Turkmenistan",
  //   iso2: "tm",
  // },
  // {
  //   name: "Turks and Caicos Islands",
  //   iso2: "tc",
  // },
  // {
  //   name: "Tuvalu",
  //   iso2: "tv",
  // },
  // {
  //   name: "Uganda",
  //   iso2: "ug",
  // },
  // {
  //   name: "Ukraine (Україна)",
  //   iso2: "ua",
  // },
  // {
  //   name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
  //   iso2: "ae",
  // },
  // {
  //   name: "United Kingdom",
  //   iso2: "gb",
  // },
  {
    name: "United States",
    iso2: "us",
  },
  // {
  //   name: "U.S. Minor Outlying Islands",
  //   iso2: "um",
  // },
  // {
  //   name: "U.S. Virgin Islands",
  //   iso2: "vi",
  // },
  // {
  //   name: "Uruguay",
  //   iso2: "uy",
  // },
  // {
  //   name: "Uzbekistan (Oʻzbekiston)",
  //   iso2: "uz",
  // },
  // {
  //   name: "Vanuatu",
  //   iso2: "vu",
  // },
  // {
  //   name: "Vatican City (Città del Vaticano)",
  //   iso2: "va",
  // },
  // {
  //   name: "Venezuela",
  //   iso2: "ve",
  // },
  // {
  //   name: "Vietnam (Việt Nam)",
  //   iso2: "vn",
  // },
  // {
  //   name: "Wallis and Futuna",
  //   iso2: "wf",
  // },
  // {
  //   name: "Western Sahara (‫الصحراء الغربية‬‎)",
  //   iso2: "eh",
  // },
  // {
  //   name: "Yemen (‫اليمن‬‎)",
  //   iso2: "ye",
  // },
  // {
  //   name: "Zambia",
  //   iso2: "zm",
  // },
  // {
  //   name: "Zimbabwe",
  //   iso2: "zw",
  // },
];
export default addFoodData;
