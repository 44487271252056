<template>
  <div class="about-us">
    <!-- Header Section -->
    <header class="bg-light py-3">
      <div class="container">
        <h1 class="display-4 text-center">About Us</h1>
        <p class="lead text-center">
          Welcome to Daily Food Serve, your ultimate guide to a healthy and
          balanced diet with our comprehensive 365-day meal plan.
        </p>
      </div>
    </header>

    <!-- Welcome to DailyFoodServe Section -->
    <section class="container py-5">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <h2>Welcome to DailyFoodServe</h2>
          <p>
            At DailyFoodServe, we are passionate about providing you with the
            best daily meal plans and recipes tailored to meet your nutritional
            needs. Whether you're a busy professional, a fitness enthusiast, or
            simply someone who loves to explore different cuisines, our website
            is your go-to source for meal inspiration.
          </p>
          <p>
            Our team of nutrition experts and culinary artists work tirelessly
            to bring you balanced and delicious meals every day. We understand
            that eating healthy doesn't have to be boring or complicated, and
            that's why we offer a variety of options that cater to diverse
            tastes and dietary preferences.
          </p>
        </div>
        <div class="col-lg-6">
          <img
            src="../../assets/welcome.png"
            class="img-fluid rounded custom-img"
            alt="Healthy Food Image"
          />
        </div>
      </div>
    </section>

    <!-- What We Offer Section -->
    <section class="container py-5">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <img
            src="../../assets/What-We-Offer.png"
            alt="Meal Plan"
            class="img-fluid rounded custom-img"
          />
        </div>
        <div class="col-lg-6">
          <h2>What We Offer</h2>
          <p>
            Our meal plans are designed by nutrition experts to ensure you get a
            balanced diet with a variety of healthy and delicious Indian dishes.
            Each meal comes with detailed information about its benefits and
            potential side effects.
          </p>
        </div>
      </div>
    </section>

    <!-- Our Mission Section -->
    <section class="container py-5">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <h2>Our Mission</h2>
          <p>
            At Daily Food Serve, we believe in the power of a well-balanced diet
            to transform lives. Our mission is to provide you with a detailed
            meal plan that caters to your nutritional needs every day of the
            year.
          </p>
        </div>
        <div class="col-lg-6">
          <img
            src="../../assets/our-mission.png"
            alt="Healthy Food"
            class="img-fluid rounded custom-img"
          />
        </div>
      </div>
    </section>

    <!-- Features Section -->
    <section class="bg-light py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img
              src="../../assets/Nutritious-Meals.png"
              class="img-fluid rounded-circle"
              alt="Nutritious Meals"
            />
            <h3 class="mt-3">Nutritious Meals</h3>
            <p>
              We provide you with meal plans that are not only delicious but
              also rich in essential nutrients to keep you healthy and
              energized.
            </p>
          </div>
          <div class="col-md-4">
            <img
              src="../../assets/Easy-to-Follow-Recipes.png"
              class="img-fluid rounded-circle"
              alt="Easy to Follow Recipes"
            />
            <h3 class="mt-3">Easy to Follow Recipes</h3>
            <p>
              Our recipes are designed to be simple and easy to follow, ensuring
              that you can prepare wholesome meals without any hassle.
            </p>
          </div>
          <div class="col-md-4">
            <img
              src="../../assets/Community-Support.png"
              class="img-fluid rounded-circle"
              alt="Community Support"
            />
            <h3 class="mt-3">Community Support</h3>
            <p>
              Join our community of food lovers and share your experiences,
              tips, and feedback. Together, we make daily dining an enjoyable
              experience.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Why Follow Us Section -->
    <section class="container my-5">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2>Why Follow Us?</h2>
          <p>
            By following DailyFoodServe, you'll gain access to a wealth of
            knowledge on nutrition, meal planning, and healthy living. Our
            mission is to make healthy eating accessible and enjoyable for
            everyone. Whether you're looking to lose weight, gain muscle, or
            simply maintain a balanced diet, we have something for you.
          </p>
          <p>
            Don't miss out on our latest updates, recipes, and exclusive
            content! Follow us today and start your journey towards a healthier,
            happier you.
          </p>
          <a href="mailto:care@dailyfoodserve.com" class="btn btn-primary mt-3">
            Subscribe Now
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  metaInfo: {
    title: "About Us - Daily Food Serve",
    meta: [
      {
        name: "description",
        content:
          "Learn more about Daily Food Serve, our mission, and our team. Discover how we provide a comprehensive 365-day meal plan for a healthy and balanced diet.",
      },
      {
        name: "keywords",
        content:
          "About Daily Food Serve, 365-day meal plan, healthy diet, balanced diet, Indian dishes, nutrition, meal plan",
      },
    ],
  },
};
</script>

<style scoped>
.about-us h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.about-us p.lead {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}
.about-us img {
  margin-bottom: 2rem;
}
.about-us h2 {
  font-size: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.custom-img {
  max-width: 100%;
  height: auto;
  width: 100%;
}
.img-fluid.rounded-circle {
  max-width: 300px;
}
@media (min-width: 992px) {
  .custom-img {
    width: 75%; /* Adjust the width as needed */
  }
}
</style>
