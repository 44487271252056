<template>
  <div
    class="mb-2 d-flex px-3 justify-content-between align-items-center flex-wrap"
  >
    <div class="order-1 order-md-0 mt-2">
      <CountryComponent @selectedCountrySent="selectedCountryGet" />
    </div>
    <h2
      class="col-12 col-md-auto order-2 order-md-1 text-center theme-text-color px-3 my-2"
    >
      <strong
        >Today's menu <small>({{ todaysFoodMenu.Date }})</small></strong
      >
    </h2>
    <h1
      class="col-12 col-md-auto order-0 order-lg-3 font-size-16 text-md-end mt-2 mb-0 mt-md-0"
    >
      <small>Welcome to Daily Food Serve</small>
    </h1>
  </div>
  <div class="col-12 p-0 px-3 overflow-x-hidden">
    <div class="col-12 p-0 d-flex food-card-wrapper overflow-x-auto">
      <div class="col pe-2">
        <div class="card-menu breakfast-card">
          <div class="food-card-header py-2 border-bottom-white">
            <h3 class="m-0 p-0">Breakfast</h3>
          </div>
          <div class="food-card-body px-3">
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">Dish</p>
                </div>
                <div class="col-8 ps-1">
                  <p class="p-0 m-0">{{ todaysFoodMenu["Breakfast"] }}</p>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">Benefits (लाभ)</p>
                </div>
                <div class="col-8 ps-1">
                  <p class="p-0 m-0">
                    {{ todaysFoodMenu["Breakfast Benefits"] }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">
                    Potential Side Effects (संभावित दुष्प्रभाव)
                  </p>
                </div>
                <div class="col-8 ps-1">
                  <p class="p-0 m-0">
                    {{ todaysFoodMenu["Breakfast Potential Side Effects"] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col pe-1">
        <div class="card-menu lunch-card">
          <div class="food-card-header py-2 border-bottom-white">
            <h3 class="m-0 p-0">Lunch</h3>
          </div>
          <div class="food-card-body px-3">
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">Dish</p>
                </div>
                <div class="col-8 ps-1">
                  <p class="p-0 m-0">{{ todaysFoodMenu["Lunch"] }}</p>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">Benefits (लाभ)</p>
                </div>
                <div class="col-8 ps-1">
                  <p class="p-0 m-0">
                    {{ todaysFoodMenu["Lunch Benefits"] }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">
                    Potential Side Effects (संभावित दुष्प्रभाव)
                  </p>
                </div>
                <div class="col-8 ps-1">
                  <p class="p-0 m-0">
                    {{ todaysFoodMenu["Lunch Potential Side Effects"] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col ps-1">
        <div class="card-menu snacks-card">
          <div class="food-card-header py-2 border-bottom-white">
            <h3 class="m-0 p-0">Snacks</h3>
          </div>
          <div class="food-card-body px-3">
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">Dish</p>
                </div>
                <div class="col-8 ps-0">
                  <p class="p-0 m-0">{{ todaysFoodMenu["Snacks"] }}</p>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">Benefits (लाभ)</p>
                </div>
                <div class="col-8 ps-0">
                  <p class="p-0 m-0">
                    {{ todaysFoodMenu["Snacks Benefits"] }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">
                    Potential Side Effects (संभावित दुष्प्रभाव)
                  </p>
                </div>
                <div class="col-8 ps-1">
                  <p class="p-0 m-0">
                    {{ todaysFoodMenu["Snacks Potential Side Effects"] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col ps-2">
        <div class="card-menu dinner-card">
          <div class="food-card-header py-2 border-bottom-white">
            <h3 class="m-0 p-0">Dinner</h3>
          </div>
          <div class="food-card-body px-3">
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">Dish</p>
                </div>
                <div class="col-8 ps-1">
                  <p class="p-0 m-0">{{ todaysFoodMenu["Dinner"] }}</p>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">Benefits (लाभ)</p>
                </div>
                <div class="col-8 ps-1">
                  <p class="p-0 m-0">
                    {{ todaysFoodMenu["Dinner Benefits"] }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 text-start border-bottom-white py-2">
              <div class="row">
                <div class="col-4 pe-0">
                  <p class="p-0 m-0">
                    Potential Side Effects (संभावित दुष्प्रभाव)
                  </p>
                </div>
                <div class="col-8 ps-1">
                  <p class="p-0 m-0">
                    {{ todaysFoodMenu["Dinner Potential Side Effects"] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryComponent from "./Country/CountryComponent.vue";
export default {
  name: "fullYearFoodMenu",
  components: {
    CountryComponent,
  },
  props: {
    todaysFoodMenu: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["selectedCountrySent"],
  data() {
    return {
      countryValue: {
        name: "India (भारत)",
        iso2: "in",
      },
    };
  },
  mounted() {
    this.$emit("selectedCountrySent", this.countryValue);
  },
  methods: {
    selectedCountryGet(val) {
      // console.log("dfsadfsd-selectedCountryGet", val);
      this.$emit("selectedCountrySent", val);
    },
  },
};
</script>

<style>
.food-card-wrapper .col {
  flex: 1;
  /* max-width: 340px; */
  min-width: 329px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.food-card-wrapper::-webkit-scrollbar {
  display: none;
}
.card-menu {
  /* width: 329px; */
  height: 330px;
  border-radius: 10px;
  overflow: hidden;
  color: #000;
  text-align: center;
  font-size: 12px;
}
.border-bottom-white {
  border-bottom: 1px solid #fff;
}
.breakfast-card {
  background-color: rgba(255, 193, 7, 0.34);
}
.lunch-card {
  background-color: rgba(76, 175, 80, 0.34);
}
.snacks-card {
  background-color: rgba(255, 87, 34, 0.34);
}
.dinner-card {
  background-color: rgba(33, 150, 243, 0.34);
}
.food-card-header {
  height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.col-12.p-0.text-start.border-bottom-white.py-2 {
  height: 90px;
}
</style>
