<template>
  <cardComponent
    :todaysFoodMenu="todaysFoodMenu"
    @selectedCountrySent="selectedCountry"
  />
  <h2 class="mt-3 mb-1 text-center theme-text-color px-3 my-2">
    <strong>Our 365-Day Meal Plan</strong>
  </h2>
  <div class="full_year_food_menu_wrapper col-12 px-3 py-2 overflow-hidden">
    <ul
      class="nav nav-tabs mb-2 d-flex flex-nowrap overflow-x-auto overflow-y-hidden"
    >
      <li
        class="nav-item col"
        v-for="month of monthList"
        :key="month"
        :class="{ active: selectedMonth == month }"
      >
        <div
          class="nav-link text-center"
          type="button"
          @click="selectedMonth !== month ? selectedTab(month) : () => {}"
        >
          {{ month }}
        </div>
      </li>
    </ul>
    <EasyDataTable
      v-if="items.length"
      :headers="headers"
      :items="items"
      border-cell
      :rows-per-page="7"
      class="sticky-table table-wrapper"
    >
      <!-- @update-page-items="updatePageItems" -->
      <template #customize-headers>
        <thead class="customize-headers">
          <tr>
            <th colspan="1" rowspan="2">Date</th>
            <th colspan="3" class="breakfast">Breakfast</th>
            <th colspan="3" class="lunch">Lunch</th>
            <th colspan="3" class="snacks">Snacks</th>
            <th colspan="3" class="dinner">Dinner</th>
          </tr>
          <tr>
            <th class="breakfast">Breakfast (नाश्ता)</th>
            <th class="breakfast">Benefits (लाभ)</th>
            <th class="breakfast">
              Potential Side Effects (संभावित दुष्प्रभाव)
            </th>

            <th class="lunch">Lunch (दोपहर का भोजन)</th>
            <th class="lunch">Benefits (लाभ)</th>
            <th class="lunch">Potential Side Effects (संभावित दुष्प्रभाव)</th>

            <th class="snacks">Snacks (नाश्ता)</th>
            <th class="snacks">Benefits (लाभ)</th>
            <th class="snacks">Potential Side Effects (संभावित दुष्प्रभाव)</th>

            <th class="dinner">Dinner (रात का भोजन)</th>
            <th class="dinner">Benefits (लाभ)</th>
            <th class="dinner">Potential Side Effects (संभावित दुष्प्रभाव)</th>
          </tr>
        </thead>
      </template>
    </EasyDataTable>
  </div>
</template>

<script>
import addFoodData from "./Data/addFoodData.js";
import cardComponent from "./cardsComponent.vue";

export default {
  name: "fullYearFoodMenu",
  metaInfo: {
    title: "365-Day Food Menu - Daily Food Serve",
    meta: [
      {
        name: "description",
        content:
          "Explore our comprehensive 365-day food menu to maintain a healthy and balanced diet throughout the year.",
      },
      {
        name: "keywords",
        content:
          "365-day food menu, Daily Food Serve, healthy diet, balanced diet, meal plan",
      },
    ],
  },
  components: {
    cardComponent,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      selectedMonth: "January",
      monthList: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      AddFoodData: addFoodData[0].country,
      headers: [
        { text: "Date", value: "Date", width: 100, sortable: true },
        {
          text: "Breakfast (नाश्ता)",
          value: "Breakfast",
          width: 200,
          sortable: true,
        },
        {
          text: "Breakfast Benefits",
          value: "Breakfast Benefits",
          width: 200,
          sortable: true,
        },
        {
          text: "Breakfast Potential Side Effects",
          value: "Breakfast Potential Side Effects",
          width: 200,
          sortable: true,
        },
        { text: "Lunch", value: "Lunch", width: 200, sortable: true },
        {
          text: "Lunch Benefits",
          value: "Lunch Benefits",
          width: 200,
          sortable: true,
        },
        {
          text: "Lunch Potential Side Effects",
          value: "Lunch Potential Side Effects",
          width: 200,
          sortable: true,
        },
        { text: "Snacks", value: "Snacks", width: 200, sortable: true },
        {
          text: "Snacks Benefits",
          value: "Snacks Benefits",
          width: 200,
          sortable: true,
        },
        {
          text: "Snacks Potential Side Effects",
          value: "Snacks Potential Side Effects",
          width: 200,
          sortable: true,
        },
        { text: "Dinner", value: "Dinner", width: 200, sortable: true },
        {
          text: "Dinner Benefits",
          value: "Dinner Benefits",
          width: 200,
          sortable: true,
        },
        {
          text: "Dinner Potential Side Effects",
          value: "Dinner Potential Side Effects",
          width: 200,
          sortable: true,
        },
      ],
      items: [],
      todaysFoodMenu: [],
      selectedCountryValue: {},
      currentMonth: new Date().getMonth(),
    };
  },
  mounted() {
    this.todaysFoodMenuHander(this.monthList[this.currentMonth]);
    this.selectedTab(this.monthList[this.currentMonth]);
  },
  methods: {
    todaysFoodMenuHander(currentMonth) {
      // let data = this.AddFoodData.unitedStates[currentMonth].value;
      let data =
        this.AddFoodData[this.selectedCountryValue.iso2][currentMonth].value;
      let todayDate = new Date().toLocaleDateString("en-CA");
      this.todaysFoodMenu = data.find((menu) => {
        return menu.Date == todayDate;
      });
    },
    selectedTab(month) {
      this.selectedMonth = month;
      let data = this.AddFoodData[this.selectedCountryValue.iso2][month].value;
      this.items = [];
      this.$nextTick(() => {
        this.items = data;
      });
      // console.log("ddddddddddddddddddddddddd", this.selectedMonth);
      // console.log("ddddddddddddddddddddddddd", this.items);
    },
    handleSortChange({ column, order }) {
      // Sort the rows array based on the column and order
      this.rows.sort((a, b) => {
        if (order === "asc") {
          return a[column.id] > b[column.id] ? 1 : -1;
        } else {
          return a[column.id] < b[column.id] ? 1 : -1;
        }
      });
    },
    selectedCountry(val) {
      this.selectedCountryValue = val;
      this.todaysFoodMenuHander(this.monthList[this.currentMonth]);
      this.selectedTab(this.monthList[this.currentMonth]);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.nav-tabs .nav-item {
  /* flex: 1; */
  text-align: center;
  max-width: 110px;
  width: 110px;
}
.nav-tabs .nav-link {
  width: 100%;
  cursor: pointer;
}
.customize-headers tr th {
  color: #000;
  border: none;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  height: 42px;
  padding: 0px 10px;
  text-align: center;
}
.breakfast,
.vue3-easy-data-table__body tr td:nth-child(2),
.vue3-easy-data-table__body tr td:nth-child(3),
.vue3-easy-data-table__body tr td:nth-child(4) {
  background-color: rgba(255, 193, 7, 0.34); /* Light Khaki for Breakfast */
}

.lunch,
.vue3-easy-data-table__body tr td:nth-child(5),
.vue3-easy-data-table__body tr td:nth-child(6),
.vue3-easy-data-table__body tr td:nth-child(7) {
  background-color: rgba(76, 175, 80, 0.34); /* Light Salmon for Lunch */
}

.snacks,
.vue3-easy-data-table__body tr td:nth-child(8),
.vue3-easy-data-table__body tr td:nth-child(9),
.vue3-easy-data-table__body tr td:nth-child(10) {
  background-color: rgba(255, 87, 34, 0.34); /* Pale Green for Snacks */
}

.dinner,
.vue3-easy-data-table__body tr td:nth-child(11),
.vue3-easy-data-table__body tr td:nth-child(12),
.vue3-easy-data-table__body tr td:nth-child(13) {
  background-color: rgba(33, 150, 243, 0.34); /* Sky Blue for Dinner */
}
.vue3-easy-data-table__main.hoverable tr:hover td {
  background-color: #ffffff00 !important;
}
.pagination__rows-per-page::after {
  content: "";
  width: 150px;
  height: 35px;
  background: transparent;
  position: absolute;
  cursor: not-allowed;
}
/* .nav-item, */
.nav-link {
  width: 100%;
  border-radius: 0;
}
.nav-tabs .nav-link {
  color: #055160;
}
.active .nav-link,
.active .nav-link div,
.active:hover .nav-link,
.active .nav-link:hover div {
  background-color: #055160;
  color: #fff;
  width: 100%;
  /* border-radius: 0; */
}
.nav-link:hover button,
.nav-link:hover button:hover {
  border-radius: 0;
  overflow: hidden;
}
/* .nav-item.active,
.nav-item.active button {
  background-color: #0dcaf0;
  color: #fff;
  width: 100%;
  border-radius: 0;
}
.nav-item:hover button,
.nav-item:hover button:hover {
  border-radius: 0;
  overflow: hidden;
} */
</style>
